import CommonFields from "./commonFields";

export const eligibilityRenderOptions = [
	{
		label: "Item Source",
		name: "method",
		field: {
			isSelect: true,
			noDefault: true,
			options: [
				{
					label: "From Cart (no items)",
					value: "LoadCart"
				},
				{
					label: "From Cart (with items)",
					value: "LoadCartItems"
				},
				{
					label: "From Saved Cart",
					value: "LoadSavedCart"
				},
				{
					label: "From Hovered Products (Category)",
					value: "LoadCategory",
				},
				{
					label: "From Category Listing Page",
					value: "LoadListPageCategory"
				},
				{
					label: "From Search Listing Page",
					value: "LoadListPageSearch"
				},
				{
					label: "From Viewed Products",
					value: "LoadItems",
				},
				{
					label: "From Saved Products",
					value: "LoadSavedItems",
				},
				{
					label: "From Product Change",
					value: "LoadItemsProductChange",
				},
				{
					label: "From Trigger Events",
					value: "LoadItemsFromTriggerEvents",
				},
				{
					label: "Custom",
					value: "Custom"
				}
			]
		},
		properties: [
      {
				label: "Item Type",
				name: "what",
				nameKey: "what",
				exclude: ["LoadCart", "LoadCartItems", "LoadSavedCart", "LoadSavedItems", "LoadListPageCategory", "LoadListPageSearch", "Custom", 'LoadItemsFromTriggerEvents'],
				excludeIfRecItemsDisabled: true,
				field: {
					isSelect: true,
					noDefault: true,
					options: [
						{
							label: "Separate Items (User + Recommended)",
							value: "user_rec",
						},
						{
							label: "All Items",
							value: "items"
						}
					]
				}
			},
      {
				label: "Selection Strategies",
				name: "selectionStrategies",
				nameKey: "selectionStrategies",
				exclude: ["LoadCart", "LoadItems", "LoadCartItems", "LoadSavedCart", 'LoadCategory', "LoadSavedItems", "LoadListPageCategory", "LoadListPageSearch", "Custom", 'LoadItemsProductChange'],
				enableOn: ['LoadItemsFromTriggerEvents'],
				excludeIfRecItemsDisabled: true,
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "None",
							value: "none",
						},
						{
							label: "Random",
							value: "random"
						}
					]
				}
			},
			{
				label: "Event Type",
				name: "and",
				nameKey: "andItemProductChange",
				enableOnKey: "method",
				enableOn: ["LoadItemsProductChange", 'LoadItemsFromTriggerEvents'],
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "Back In Stock",
							value: "IsBackInStock",
							exclude: ['LoadItemsFromTriggerEvents']
						},
						{
							label: "Low Stock",
							value: "IsLowStock",
							exclude: ['LoadItemsFromTriggerEvents']
						},
						{
							label: "Price Drop",
							value: "IsPriceDrop",
						},
						{
							label: "New Product",
							value: "IsNewProduct",
							include: ['LoadItemsFromTriggerEvents']
						},
						{
							label: "Cart-Cross Sell",
							value: "IsCartCrossSell",
							include: ['LoadItemsFromTriggerEvents']
						},
						{
							label: "Out Of Stock",
							value: "IsOutOfStock",
							include: ['LoadItemsFromTriggerEvents']
						},
					]
				}
			},
			{
				label: "Min",
				name: "min",
				nameKey: "min",
				exclude: ["Custom"],
				field: {
					isInput: true,
					type: "number",
					size: "s",
					sizeString: "s",
					allowEmpty: true,
					dontAllowEmptyOrZeroFor: ['LoadCategory', 'LoadListPageCategory', 'LoadListPageSearch', 'LoadItems', 'LoadSavedItems', 'LoadItemsProductChange']
				}
			},
			{
				label: "Max",
				name: "max",
				nameKey: "max",
				exclude: ["Custom"],
				field: {
					isInput: true,
					type: "number",
					size: "s",
					sizeString: "s",
					allowEmpty: true,
					dontAllowEmptyOrZeroFor: ['LoadCategory', 'LoadListPageCategory', 'LoadListPageSearch', 'LoadItems', 'LoadSavedItems', 'LoadItemsProductChange']
				}
			},
			{
				label: "During the last",
				name: "when",
				nameKey: "n",
				placeholder: "N/A",
				field: CommonFields.genericNumber,
				enableOnKey: "period",
				enableOn: ["day", "hour", "minute"],
				exclude: ["LoadCart", "LoadCartItems", "Custom", 'LoadItemsFromTriggerEvents'],
			},
			{
				label: "When",
				name: "whenperiod",
				nameKey: "period",
				exclude: ["LoadCart", "LoadCartItems", "Custom", "LoadItemsFromTriggerEvents"],
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "Days",
							value: "day"
						},
						{
							label: "Hours",
							value: "hour"
						},
						{
							label: "Minutes",
							value: "minute"
						},
						{
							label: "This Visit",
							value: "trigger visit"
						},
						{
							label: "Trigger Event",
							value: "trigger event"
						}
					]
				}
			},
			{
				label: "Source of Recommendation",
				name: "recommendations",
				nameKey: "recommendations",
				exclude: ["LoadCart", "LoadCartItems", "LoadSavedCart", "LoadSavedItems", "LoadListPageCategory", "LoadListPageSearch", "Custom", 'LoadItemsFromTriggerEvents','LoadItemsProductChange'],
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "Same Category",
							value: "user categories",
						},
						{
							label: "All Categories",
							value: "global"
						},
						{
							label: "None",
							value: "none",
						}
					]
				}
			},
			{
				label: "Source of Recommendation",
				name: "cartRec",
				nameKey: "cartRec",
				exclude: ['LoadCart', 'LoadSavedCart', 'LoadCategory', 'LoadListPageCategory', 'LoadListPageSearch', 'LoadItems', 'LoadSavedItems', 'LoadItemsProductChange', 'LoadItemsFromTriggerEvents'],
				excludeIfRecItemsDisabled: true,
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "All Categories",
							value: "global"
						},
						{
							label: "AI",
							value: "ai"
						},
						{
							label: "None",
							value: "none",
						}
					]
				}
			},
			{
				label: "Source of Recommendation",
				name: "productRec",
				nameKey: "productRec",
				exclude: ["LoadCart", "LoadSavedCart", "LoadCategory", "LoadCartItems", 'LoadListPageCategory', "LoadItems", "LoadSavedItems", "LoadListPageSearch", "Custom", 'LoadItemsFromTriggerEvents'],
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "Same Category",
							value: "user categories",
						},
						{
							label: "All Categories",
							value: "global"
						},
						{
							label: "AI",
							value: "ai"
						},
						{
							label: "None",
							value: "none",
						}
					]
				}
			},
			{
				label: "Source of Recommendation",
				name: "pageCategoryRec",
				nameKey: "pageCategoryRec",
				exclude: ['LoadCart', 'LoadCartItems', 'LoadSavedCart', 'LoadCategory', 'LoadListPageSearch', 'LoadItems', 'LoadSavedItems', 'LoadItemsProductChange', 'LoadItemsFromTriggerEvents', 'Custom'],
				excludeIfRecItemsDisabled: true,
				field: {
					isSelect: true,
					sizeString: "m",
					noDefault: true,
					options: [
						{
							label: "AI",
							value: "ai"
						},
						{
							label: "None",
							value: "none",
						}
					]
				}
			},
			{
				label: "Rec Min",
				name: "recommendationsMin",
				nameKey: "recommendationsMin",
				enableOnObject: {"what": "user_rec", "pageCategoryRec": "ai", 'cartRec': ['global', 'ai']},
				exclude: ["LoadCart", "LoadSavedCart", "LoadSavedItems", "LoadItemsProductChange", "LoadListPageSearch", "Custom", 'LoadItemsFromTriggerEvents', 'productRec'],
				excludeOnKey: "recommendations",
				excludeOn: ["none", '', null],
				excludeIfRecItemsDisabled: true,
				field: {
					isInput: true,
					type: "number",
					size: "s",
					sizeString: "s"
				}
			},
			{
				label: "Rec Max",
				name: "recommendationsMax",
				nameKey: "recommendationsMax",
				enableOnObject: {"what": "user_rec", "pageCategoryRec": "ai", 'cartRec': ['global', 'ai']},
				exclude: ["LoadCart", "LoadSavedCart", "LoadSavedItems", "LoadItemsProductChange", "LoadListPageSearch", "Custom", 'LoadItemsFromTriggerEvents', 'productRec'],
				excludeOnKey: "recommendations",
				excludeOn: ["none", '', null],
				excludeIfRecItemsDisabled: true,
				field: {
					isInput: true,
					type: "number",
					size: "s",
					sizeString: "s"
				}
			}
		]
	}
];
